:root {
    /* INPUT */
    --search-input-stroke: var(--c-neutral-300);
    --search-input-stroke-active: var(--c-primary-800);
    --search-input-color: var(--c-neutral-900);
    --search-slot-rolling-effect-color: #a9a9a9;
    --search-input-icon-x: var(--c-neutral-500);
    --search-title-font-weight: 700;
    --search-title-bottom-margin: 18px;
    --search-title-line-height: 31.5px;
    --search-title-font-size: 18px;
    --margin-input-icon-magnify: 12px;

    /* ICON */
    --search-icon-arrow: url('../../search/image/jackpotjoy/arrow.svg');
    --search-icon-magnify: url('../../search/image/jackpotjoy/magnify.svg');
    --search-icon-close: url('../../search/image/jackpotjoy/close.svg');
    --search-icon-clear: url('../../search/image/jackpotjoy/clear.svg');

    /* BACKGROND */
    --search-bg-search: var(--c-neutral-100);
    --search-bg-placeholder: linear-gradient(90deg, var(--c-neutral-200) 0%, var(--c-neutral-500) 100%);
    --search-background-radius: 8px;
    --search-background-mobile-radius: 8px;
    --search-overlay-backdrop-color: #000;

    /* GAME GRIDS */
    --search-results-grid: repeat(2, 1fr);
    --search-results-sm-grid: repeat(2, 1fr);
    --search-results-md-grid: repeat(3, 1fr);
    --search-results-lg-grid: repeat(3, 1fr);
    --search-carousel-width: 300px;
    --search-game-tile-height: 190px;

    /* FILTER DROPDOWN */
    --search-filter-bg-color: var(--c-neutral-600);
    --search-filter-stroke: var(--c-neutral-600);
    --search-filter-text: var(--c-neutral-050);
    --search-filter-icon: var(--c-neutral-050);
    --search-number-of-results-text: var(--c-neutral-700);
    --search-no-results-header: var(--c-primary-800);
    --search-no-results-message: var(--c-neutral-700);
    --search-input-border-width: 1px;

    /* PADDINGS */
    --search-result-padding-top: 1em;
}